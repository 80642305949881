import { Header, UserHeader } from 'components/layout/App/Header'
import { SiteFooter } from 'components/layout/App/SiteFooter'
import { withCleanLayout } from 'components/layout/Clean/CleanLayout'
import { getAllLandingPages } from 'lib/clients/contentful/getAllLandingPages'
import { parseContentfulData } from 'lib/helpers/parseContentfulData'
import { pirsch } from 'lib/helpers/pirsch'
import { useCurrentUser } from 'lib/hooks/useCurrentUser'
import { NextSeo } from 'next-seo'
import { FreeportPage } from 'pages/_app'
import { useEffect, useRef } from 'react'
import { AsSeeonOnSection } from 'views/home/AsSeenOnSection'
import { GallerySection } from 'views/home/GallerySection'
import { HeroSection } from 'views/home/HeroSection'
import { HowFreeportWorks } from 'views/home/HowFreeportWorks'
import { JamesDeanFeatured } from 'views/home/JamesDeanFeatured'
import { MarilynFeatured } from 'views/home/MarilynFeatured'
import { MetricsSection } from 'views/home/MetricsSection'

export const getStaticProps = async () => {
  const landing_pages = await getAllLandingPages()
  const landing_page = parseContentfulData(landing_pages[0])

  return { props: { landing_page } }
}

type LandingPageAlt = FreeportPage & {
  landing_page: {
    bannerSection?: any
    heroSection?: any
    asSeenOnSection?: any
    howFreeportWorksSection?: any
    metricsSection?: any
    firstFeaturedSection?: any
    gallerySection?: any
    warholCollectionSection?: any
    secondFeaturedSection?: any
  }
}

const LandingPageAlt = ({ landing_page }: LandingPageAlt) => {
  const warholCollection = useRef<HTMLDivElement>(null)
  const { user } = useCurrentUser()
  const {
    bannerSection,
    heroSection,
    asSeenOnSection,
    howFreeportWorksSection,
    metricsSection,
    firstFeaturedSection,
    gallerySection,
    warholCollectionSection,
    secondFeaturedSection,
  } = landing_page

  const handleScrollToCollection = () => {
    warholCollection.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    pirsch('landing_page_index-alt')
  }, [])

  return (
    <>
      <NextSeo />

      <main className="flex-grow">
        {user ? <UserHeader /> : <Header bannerProps={bannerSection} />}

        <HeroSection
          handleScrollTo={handleScrollToCollection}
          {...heroSection}
        />

        <div className="bg-white relative z-10">
          <AsSeeonOnSection {...asSeenOnSection} />
          <HowFreeportWorks {...howFreeportWorksSection} />
          <MetricsSection {...metricsSection} />
          <MarilynFeatured {...firstFeaturedSection} />
          <GallerySection
            forwardedRef={warholCollection}
            warholCollectionProps={warholCollectionSection}
            {...gallerySection}
          />
          <JamesDeanFeatured {...secondFeaturedSection} />
        </div>
      </main>
      <SiteFooter />
    </>
  )
}

LandingPageAlt.layout = withCleanLayout

export default LandingPageAlt
